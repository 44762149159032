const properSVG = (type) => {
  // console.log(type);
  return getIcon(type);
}

const mapIcon = import.meta.globEager(
  "../assets/icons/map/pins_png/*.svg"
);

const getIcon = (icon) => {
  const iconUrl = mapIcon[`../assets/icons/map/pins_png/icone-${icon}.svg`];
  return iconUrl.default;
};

export { properSVG };