import { addMinutes, subMinutes } from "date-fns";
import cloneDeep from "lodash/cloneDeep";
import tzlookup from "tz-lookup";
import { format } from "date-fns";

const convertZonedToUTC = (date) => {
    const formated = formatDateTime(addMinutes(date, date.getTimezoneOffset()));
    return formated
}

const convertUTCToZoned = (date) => {
    const formated = formatDateTime(subMinutes(date, date.getTimezoneOffset()));
    return formated
}

const formatDateTime = (date) => {
  if (typeof date === "string") {
    date = new Date(date);
  }
  const datetime = cloneDeep(date,);
  const fullDate = formatDate(datetime);
  const fullTime = [
    datetime.getHours() >= 10 ? datetime.getHours() : `0${datetime.getHours()}`,
    datetime.getMinutes() >= 10 ? datetime.getMinutes() : `0${datetime.getMinutes()}`,
    datetime.getSeconds() >= 10 ? datetime.getSeconds() : `0${datetime.getSeconds()}`
  ].join(":",);
  const dformat = `${fullDate}T${fullTime}`;
  return dformat;
}

const goodTimeFormat = (value) => {
  return value > 10 ? value : `0${value}`;
}

const formatTime = (date) => {
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");
  return `${hours}:${minutes}:${seconds}`;
}

const formatDate = (date) => {
  const datetime = cloneDeep(date,);
  const fullDate = [
    datetime.getFullYear(),
    datetime.getMonth() + 1 >= 10 ? datetime.getMonth() + 1 : `0${datetime.getMonth() + 1}`,
    datetime.getDate() >= 10 ? datetime.getDate() : `0${datetime.getDate()}`
  ].join("-",);
  return fullDate;
}

const sub = (date, value, unit = "h") => {
  if (unit === "h") {
    date.setTime(date.getTime() - value*60*60*1000);
  }
  if (unit === "d") {
    date.setDate(date.getDate() - value);
  }
  if (unit === "m") {
    date.setMinutes(date.getMinutes() - value);
  }
  if (unit === "s") {
    date.setSeconds(date.getSeconds() - value);
  } 
  return date;
}

const add = (date, value, unit = "h") => {
  if (unit === "h") {
    date.setTime(date.getTime() + value*60*60*1000);
  }
  if (unit === "d") {
    date.setDate(date.getDate() + value);
  }
  if (unit === "m") {
    date.setMinutes(date.getMinutes() + value);
  }
  if (unit === "s") {
    date.setSeconds(date.getSeconds() + value);
  } 
  return date;
}

const timeZone = (lat, lng) => {
  return tzlookup(lat, lng)?.replace("_", " ")
}

const formatedDate = (time, second = false) => {
  if (isNaN(parseFloat(time))) {
    try {
      const date = Date.parse(time);
      return `${format(date, "PP")} ${format(date, second ? "pp" : "p")}`;
    } catch {
      return "Invalid Date";
    }
  }
  try {
    const date = new Date(time);
    return `${format(date, "PP")} ${format(date, "p")}`;
  } catch {
    return "Invalid Date";
  }
};

export { convertZonedToUTC, convertUTCToZoned, formatDateTime, formatDate, goodTimeFormat, add, sub, timeZone, formatedDate, formatTime }