import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

export default {
  mounted(el, binding) {
    tippy(el, {
      content: binding.value,
      arrow: true,
      animation: 'fade',
      duration: [100, 100], // fade in/out
      theme: 'light-border',
    });
  },
  updated(el, binding) {
    if (el._tippy) {
      el._tippy.setContent(binding.value);
    }
  }
};
