import { createWebHistory, createRouter } from "vue-router"

//LOGIN
//MAP
import MapVehicules from "../views/map/vehicules/MapVehicules.vue";
import MapRoutes from "../views/map/routes/MapRoutes.vue";
//ANALYTICS
import AnalyticsOverspeed from "../views/analytics/Overspeed.vue"
import AnalyticsBridge from "../views/analytics/Geofence.vue"

//STORE
import userStore from "../store/user"

const routes = [
  //Public routes
  {
    path: "/login",
    component: () => import('../layouts/PublicLayout.vue'),
    children: [
      {
        path: "",
        name: "Login",
        component: () => import('../views/login/Login.vue'),
      }
    ]
  },
  {
    path: "/forgot-password",
    component: () => import('../layouts/PublicLayout.vue'),
    children: [
      {
        path: "",
        name: "ForgotPassword",
        component: () => import('../views/forgotPassword/ForgotPassword.vue'),
      }
    ]
  },
  {
    path: "/logout",
    name: "Logout",
    component: () => import('../views/logout/Logout.vue'),
  },
  //App routes
  {
    path: "/",
    component: () => import('../layouts/AppLayout.vue'),
    children: [
      {
        path: '/',
        redirect: '/map/vehicles/'
      },
      {
        path: "/map/vehicles/:id?",
        name: 'MapVehicules',
        component: MapVehicules,
        meta: { requiresAuth: true, breadcrumb: ["Map", "Vehicle Locator"] }
      },

      {
        path: "/map/routes/:uuid?",
        name: 'MapRoutes',
        component: MapRoutes,
        meta: { requiresAuth: true, breadcrumb: ["Map", "Route Analysis"] }
      },

      {
        path: "/analytics/overspeed",
        name: 'AnalyticsOverspeed',
        component: AnalyticsOverspeed,
        meta: { requiresAuth: true, breadcrumb: ["Analytics", "Overspeed"] },
        redirect: "/analytics/overspeed/report",
        children: [
          {
            path: "report",
            name: "AnalyticsOverspeedReport",
            component: () => /* webpackChunkName: "analytics-overspeed-report" */ import('../components/pages/analytics/overspeed/report/OverspeedReport.vue')
          },
          {
            path: "stats",
            name: "AnalyticsOverspeedStats",
            component: () => /* webpackChunkName: "analytics-overspeed-stats" */ import('../components/pages/analytics/overspeed/stats/OverspeedStats.vue')
          },
          {
            path: "map",
            name: "AnalyticsOverspeedMap",
            component: () => /* webpackChunkName: "analytics-overspeed-map" */ import('../components/pages/analytics/overspeed/map/OverspeedMap.vue')
          }
        ]
      },
      {
        path: "/analytics/geofences",
        name: 'AnalyticsGeofences',
        component: AnalyticsBridge,
        meta: { requiresAuth: true, breadcrumb: ["Analytics", "Geofences"] },
        redirect: "/analytics/geofences/report",
        children: [
          {
            path: "report",
            name: "GeofencesReport",
            component: () => /* webpackChunkName: "analytics-bridge-report" */ import('../components/pages/analytics/geofences/report/GeofenceReport.vue')
          },
          {
            path: "map",
            name: "GeofencesMap",
            component: () => /* webpackChunkName: "analytics-bridge-map" */ import('../components/pages/analytics/geofences/map/GeofenceMap.vue')
          }
        ]
      },
      {
        path: "/analytics/highwaystops",
        name: 'AnalyticsHighwayStops',
        component: () => /* webpackChunkName: "highwaystops" */ import('../views/analytics/HighwayStop.vue'),
        meta: { requiresAuth: true, breadcrumb: ["Analytics", "Highway Stops"], roles: ["admin", "super"] },
        redirect: "/analytics/highwaystops/report",
        children: [
          {
            path: "report",
            name: "HighwayStopsReport",
            component: () => /* webpackChunkName: "analytics-highway-stop-report" */ import('../components/pages/analytics/highwayStops/report/HighwayStopReport.vue')
          },
          {
            path: "map",
            name: "HighwayStopsMap",
            component: () => /* webpackChunkName: "analytics-highway-stop-report" */ import('../components/pages/analytics/highwayStops/map/HighwayStopMap.vue')
          }
        ]
      },
      {
        path: "/vehicles-settings",
        meta: { requiresAuth: true, breadcrumb: ["Management", "Settings"], roles: ["admin", "super", "manager"]},
        component: () => /* webpackChunkName: "vehicules-settings" */ import('../views/vehicles/Settings.vue'),
        redirect: "/vehicles-settings/one",
        children: [
          {
            path: "one/:id?",
            name: "VehiclesSettingsOne",
            component: () => /* webpackChunkName: "vehicles-settings-one" */ import('../components/pages/vehicles/settings/one/VehiclesSettingsOne.vue')
          },
          {
            path: "multiples/:units?",
            name: "VehiclesSettingsMultiples",
            component: () => /* webpackChunkName: "vehicles-settings-multiples" */ import('../components/pages/vehicles/settings/multiples/VehiclesSettingsMultiples.vue'),
          }
        ]
      },
      {
        path: "/vehicles-all-settings",
        meta: { requiresAuth: true, breadcrumb: ["Management", "Settings"], roles: ["admin", "super", "manager"], featureGate: "client_health_pages"},
        component: () => /* webpackChunkName: "vehicules-settings" */ import('../views/vehicles/AllSettings.vue'),
        redirect: "/vehicles-settings/speeds",
        children: [
          {
            path: "speeds/:units?",
            name: "VehiclesSpeedSettings",
            component: () => /* webpackChunkName: "vehicles-settings-multiples" */ import('../components/pages/vehicles/settings/speeds/VehicleSpeedSettings.vue'),
          },
          {
            path: "installation/:units?",
            name: "VehicleInstallationSettings",
            component: () => /* webpackChunkName: "vehicles-settings-multiples" */ import('../components/pages/vehicles/settings/installation/VehicleInstallation.vue'),
          }
        ]
      },
      {
        path: "/vehicles-status",
        meta: { requiresAuth: true, breadcrumb: ["Management", "Status"], roles: ["admin", "super", "manager"]},
        component: () => /* webpackChunkName: "vehicules-status" */ import('../views/vehicles/Status.vue'),
        redirect: "/vehicles-status/report",
        children: [
          {
            path: "report",
            name: "VehiclesStatusReport",
            component: () => /* webpackChunkName: "vehicles-status-report" */ import('../components/pages/vehicles/status/report/VehiclesStatusReport.vue')
          },
          {
            path: "stats",
            name: "VehiclesStatusStats",
            component: () => /* webpackChunkName: "vehicles-status-stats" */ import('../components/pages/vehicles/status/stats/VehiclesStatusStats.vue')
          }
        ]
      },
      {
        path: "/vehicles-speed",
        meta: { requiresAuth: true, breadcrumb: ["Management", "Vehicle Speeds"], roles: ["admin", "super", "manager"], featureGate: "client_health_pages"},
        component: () => /* webpackChunkName: "vehicules-status" */ import('../views/vehicles/Speeds.vue'),
        redirect: "/vehicles-speed/report",
        children: [
          {
            path: "report",
            name: "VehiclesSpeedReport",
            component: () => /* webpackChunkName: "vehicles-speed-report" */ import('../components/pages/vehicles/speed/report/VehicleSpeedReport.vue')
          },
        ]
      },
      {
        path: "/vehicles-health",
        meta: { requiresAuth: true, breadcrumb: ["Management", "Health Status"], roles: ["admin", "super", "manager"], featureGate: "client_health_pages"},
        component: () => /* webpackChunkName: "vehicules-status" */ import('../views/vehicles/Health.vue'),
        redirect: "/vehicles-health/report",
        children: [
          {
            path: "report",
            name: "VehiclesHealthReport",
            component: () => /* webpackChunkName: "vehicles-health-report" */ import('../components/pages/vehicles/health/report/VehicleHealthReport.vue')
          },
          {
            path: "map",
            name: "VehiclesHealthMap",
            component: () => /* webpackChunkName: "vehicles-health-map" */ import('../components/pages/vehicles/health/map/VehicleHealthMap.vue')
          },
        ]
      },
      {
        path: "/fleet",
        meta: { requiresAuth: true, breadcrumb: ["fleet", "installations"], roles: ["admin", "super"]},
        component: () => /* webpackChunkName: "vehicules-status" */ import('../views/vehicles/Installation.vue'),
        redirect: "/fleet/installations",
        children: [
          {
            path: "installations",
            name: "Installations",
            meta: { requiresAuth: true, breadcrumb: ["fleet", "Installations"], roles: ["admin", "super"] },
            component: () => /* webpackChunkName: "vehicles-status-report" */ import('../components/pages/vehicles/installations/InstallationList.vue')
          },
          {
            path: "uninstallations",
            name: "Uninstallations",
            meta: { requiresAuth: true, breadcrumb: ["fleet", "Uninstallations"], roles: ["admin", "super"] },
            component: () => /* webpackChunkName: "vehicles-status-report" */ import('../components/pages/vehicles/installations/UnInstallationList.vue')
          }
        ]
      },
      {
        path: "/zones/",
        name: "ZonesListing",
        component: () => /* webpackChunkName: "zone-listings" */ import('../views/zones/listing/ZoneList.vue'),
        meta: { requiresAuth: true, breadcrumb: ["Zones", "List"], roles: ["admin", "super"] },
        redirect: "/zones/list",
        children: [
          {
            path: "list",
            name: "ZonesList",
            meta: { requiresAuth: true, breadcrumb: ["Zones", "List"] },
            component: () => /* webpackChunkName: "zone-list" */ import('../components/pages/zones/list/listing/ZonesListing.vue'),

          },
          {
            path: "map",
            name: "ZonesMap",
            meta: { requiresAuth: true, breadcrumb: ["Zones", "Map"] },
            component: () => /* webpackChunkName: "zone-list" */ import('../components/pages/zones/list/map/ZonesMap.vue'),

          },
          {
            path: "updates",
            name: "ZonesUpdates",
            meta: { requiresAuth: true, breadcrumb: ["Zones", "Updates"], roles: ["admin", "super"], featureGate: "deploy_zones_entire_fleet" },
            component: () => /* webpackChunkName: "zone-list" */ import('../components/pages/zones/updates/ZonesUpdates.vue'),

          },
          {
            path: "edit/:uuid",
            name: "ZoneEditing",
            meta: { requiresAuth: true, breadcrumb: ["Zone", "Edit"] },
            component: () => /* webpackChunkName: "zone-list-editing" */ import('../components/pages/zones/list/editing/ZonesEditing.vue'),
          },
          {
            path: "create",
            name: "ZoneCreating",
            meta: { requiresAuth: true, breadcrumb: ["Zone", "Create"] },
            component: () => /* webpackChunkName: "zone-list-editing" */ import('../components/pages/zones/list/creating/ZonesCreating.vue'),
          },
        ]
      },
      {
        path: "/zones/notifications",
        meta: { requiresAuth: true, breadcrumb: ["Zones", "Notifications"], roles: ["admin", "super"] },
        children: [
          {
            path: "list",
            name: "ZonesNotificationsListing",
            component: () => /* webpackChunkName: "zone-list" */ import('../components/pages/zones/notifications/listing/ZonesNotificationsListing.vue'),
          },
          {
            path: "create",
            name: "ZonesNotificationsCreating",
            meta: { requiresAuth: true, breadcrumb: ["Zones", "Create"] },
            component: () => /* webpackChunkName: "zone-list-editing" */ import('../components/pages/zones/notifications/creating/ZonesNotificationCreating.vue'),
          },
          {
            path: "edit/:id",
            name: "ZonesNotificationsEditing",
            meta: { requiresAuth: true, breadcrumb: ["Zones", "Edit"] },
            component: () => /* webpackChunkName: "zone-list-editing" */ import('../components/pages/zones/notifications/editing/ZonesNotificationEditing.vue'),
          }
        ]
      },
      {
        path: "/changes-history",
        name: "ChangesHistory",
        component: () => /* webpackChunkName: "vehicules-status" */ import('../views/vehicles/History.vue'),
        meta: { requiresAuth: true, breadcrumb: ["Management", "Changes History"], roles: ["admin", "super", "manager"] },
      },
      {
        path: "/profile",
        name: 'Profile',
        component: () => /* webpackChunkName: "my-profile" */ import('../views/profile/Profile.vue'),
        meta: { requiresAuth: true, breadcrumb: ["Profile"] }
      },
      {
        path: "/admin/",
        name: "Admin",
        redirect:"/admin/users",
        meta: { requiresAuth: true, breadcrumb: ["Admin", "Users"], roles: ["admin", "super"] },
        children: [
          {
            path: "users",
            name: "AdminUsers",
            meta: { requiresAuth: true, breadcrumb: ["Admin", "Users"], roles: ["admin", "super"] },
            component: () => /* webpackChunkName: "admin-users-list" */ import('../components/pages/admin/users/AdminUsersList.vue'),
          },
          {
            path: "users/create",
            name: "AdminUsersCreate",
            meta: { requiresAuth: true, breadcrumb: ["Admin", "Users/Create"], roles: ["admin", "super"] },
            component: () => /* webpackChunkName: "admin-users-create" */ import('../components/pages/admin/users/AdminUsersCreate.vue'),
          },
          {
            path: "users/edit/:id",
            name: "AdminUsersEdit",
            meta: { requiresAuth: true, breadcrumb: ["Admin", "Users/Edit"], roles: ["admin", "super"] },
            component: () => /* webpackChunkName: "admin-users-edit" */ import('../components/pages/admin/users/AdminUsersEdit.vue'),
          },
        ]
      },
    ]
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

const reRouteNotAuthorized = (meta, userStore, next) => {
  // Check if roles are valid or if the user belongs to a valid role
  if (!meta.roles || meta.roles.includes(userStore.state.user.user_group)) {
    // Check if a feature gate is specified
    if (meta.featureGate) {
      // Verify if the user has access to the feature
      if (userStore.state.user.featuresAccess?.[meta.featureGate]) {
        next(); // Proceed to the next route
      } else {
        next({ path: '/' }); // Redirect to the home page
      }
    } else {
      // If no feature gate is required, simply proceed
      next();
    }
  } else {
    next({ path: '/' }); // Redirect to the home page
  }
};


router.beforeEach(async (to, from, next) => {
  if (to.name === 'Logout') {
    next()
  } else {
    //If this route need authentication
    if (to.matched.some(record => record.meta.requiresAuth)) {
      //Check if a user is authenticated
      if (!userStore.isAuthenticated.value) {
        //Try to auto loggin user
        await userStore.autoLogin()
        //If the auto loggin is not valid
        if (!userStore.isAuthenticated.value) {
          next({
            path: '/login'
          })
        }
        //Else if auto loggin is valid
        else {
          reRouteNotAuthorized(to.meta, userStore, next);
        }
      } else {
        reRouteNotAuthorized(to.meta, userStore, next);
      }
    } else {
      if (userStore.isAuthenticated.value) {
        next({ path: '/' })
      } else {
        next()
      }
    }
  }
})

export default router;
