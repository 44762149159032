<template>
  <p>
    <span v-if="value" class="text-green-500">
        {{ t(`mapPage.route.on`) }}
    </span>
    <span v-else class="text-red-500">
        {{ t(`mapPage.route.off`) }}
    </span>
  </p>
</template>
  
<script setup>
import { useTranslation } from "../../composables/lang";

const { t } = useTranslation();

const props = defineProps({
  value: {
    type: Boolean,
  },
});

</script>
