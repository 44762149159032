import pinIdle from "../assets/icons/map/pins/pin-idle.svg";
import pinBridge from "../assets/icons/map/pins/bridge.png";
import pinOverspeed from "../assets/icons/map/pins/pin-overspeed.svg";
import pinOverspeedStart from "../assets/icons/map/pins_png/icone-overspeed-start.svg";
import pinRunning from "../assets/icons/map/pins/pin-running.svg";
import PinStopped from "../assets/icons/map/pins/pin-stopped.svg";
import schoolZone from "../assets/icons/map/pins/pin-schoolzone.svg";
import truckSpecific from "../assets/icons/map/pins_png/icone-truck-specific.svg";
import csa1 from "../assets/icons/map/pins_png/icone-csascore-1.svg";
import csa2 from "../assets/icons/map/pins_png/icone-csascore-2.svg";
import csa3 from "../assets/icons/map/pins_png/icone-csascore-3.svg";
import csa4 from "../assets/icons/map/pins_png/icone-csascore-4.svg";
import csa5 from "../assets/icons/map/pins_png/icone-csascore-5.svg";

// TODO: It is ugly but don't see better way to do it right now
const LEGENDS = {
    "/map/vehicles": [
        {
            label: "Stopped",
            icon: PinStopped,
        },
        {
            label: "Idle",
            icon: pinIdle,
        },
        {
            label: "Running",
            icon: pinRunning,
        },
        {
            label: "SchoolZone",
            icon: schoolZone
        },
        {
            label: "TruckSpecific",
            icon: truckSpecific
        }
    ],
    "/map/routes": [
        {
            label: "Stopped",
            icon: PinStopped,
        },
        {
            label: "Idle",
            icon: pinIdle,
        },
        {
            label: "Running",
            icon: pinRunning,
        },
        {
            label: "SchoolZone",
            icon: schoolZone
        },
        {
            label: "TruckSpecific",
            icon: truckSpecific
        },
        {
            label: "Overspeed",
            icon: pinOverspeed,
        },
        {
            label: "GeoFence",
            icon: pinBridge,
        }
    ],
    "/analytics/overspeed/map": [
        {
            label: "Running",
            icon: pinRunning,
        },
        {
            label: "Overspeed",
            icon: pinOverspeed,
        },
        {
            label: "OverspeedStart",
            icon: pinOverspeedStart,
        },
    ],
}

const CSALENGENDS = [
    {
        label: "csa1",
        icon: csa1,
    },
    {
        label: "csa2",
        icon: csa2,
    },
    {
        label: "csa3",
        icon: csa3,
    },
    {
        label: "csa4",
        icon: csa4,
    }
]

export { LEGENDS, CSALENGENDS };