<template>
  <router-link
    :to="{
      name: tab.route,
      params: { ...$route.params } // Spread current query params
    }"
    class="tab"
    :class="isActive ? 'active' : ''"
  >
    {{ t(`main_toolbar.${tab.title}`) }}
  </router-link>
</template>

<script>
import { computed } from "vue-demi";
import { useTranslation } from "../../../composables/lang";

export default {
  props: {
    tab: Object,
    isActive: Boolean,
  },
  setup(props) {
    const { t, locale } = useTranslation();
    const tab = computed(() => {
      return props.tab;
    });
    const isActive = computed(() => {
      return props.isActive;
    });

    return { props, tab, isActive, t, locale };
  },
};
</script>

<style lang="scss" scoped>
.tab {
  height: 50px;
  width: 175px;
  font-weight: 600;

  @apply bg-white text-black rounded flex items-center justify-center cursor-pointer transition-all;

  &.active {
    @apply bg-darkblue text-white cursor-default;
  }

  &:hover {
    @apply bg-darkblue text-white cursor-pointer;
  }
}
</style>