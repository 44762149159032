<template>
  <div class="flex items-center justify-between z-10 w-full">
    <div class="flex items-center justify-start">
      <div class="mr-2 w-full md:w-[200px]">
        <AutocompleteField
          ref="unitnameSelector"
          :placeholder="t(`mapPage.route.search.placeholder`)"
          :payload="filters.unit_name"
          :options="userUnits.map((u) => u.unit_name)"
          @selected="(e) => (filters.unit_name = e)"
        />
      </div>
      <div class="mr-2 w-[200px]">
        <Datepicker v-model="filters.date" format="yyyy-MM-dd" auto-apply />
      </div>
      <div class="mr-2 w-[200px]">
        <Datepicker
          v-model="filters.timeRange"
          time-picker
          range
          max-range="1"
          :placeholder="t(`mapPage.route.search.placeHolderTime`)"
          is-24
          auto-apply
        />
      </div>
      <Button
        class="ml-2 mr-2 w-[155px]"
        :isDisabled="!filtersValid"
        :isLoading="props.loading"
        @clicked="search()"
      >
        <span> {{ t(`mapPage.route.search.submit`) }} </span>
      </Button>
      {{ props.error }}
    </div>
    <div class="mr-2 w-[550px] flex">
      <Toggle
        class="flex mr-10"
        :text="t(`mapPage.route.search.zones`)"
        v-model="props.isZonesShown"
        @click="showZone()"
        v-if="!!userStore.state.user.company.settings.notification_enable"
      />
      <!-- <Toggle
        class="flex mr-10"
        :text="t(`mapPage.route.search.truck`)"
        v-model="props.isTruckSpecific"
        @click="showTruckSpecific()"
      /> -->
      <Toggle
        class="flex"
        :text="t(`mapPage.route.search.csaScore`)"
        v-model="props.isSCAScoreDisplayed"
        v-if= "userStore.state?.user?.featuresAccess?.see_csa_score"
        @click="displayCSAscore()"
      />
      <ResetFilters @clicked="resetFilters" />
    </div>
  </div>
</template>

<script setup>
import { reactive, ref, computed, inject, watch } from "vue";
import Datepicker from '@vuepic/vue-datepicker';
import { useTranslation } from "../../../../composables/lang";
import '@vuepic/vue-datepicker/dist/main.css';
import router from "../../../../router";
import { goodTimeFormat, formatDate } from "../../../../utils/dates";

const notificationsStore = inject("notificationsStore");

const { t } = useTranslation();

const props = defineProps({
  payload: Object,
  error: String,
  loading: Boolean,
  isZonesShown: Boolean,
  isTruckSpecific: Boolean,
  isSCAScoreDisplayed: Boolean
});

const filters = reactive({
  uuid: "",
  unit_name: "",
  date: null,
  timeRange: []
});

const emit = defineEmits(["showingZone", "showingTruckSpecific", "displayCSAscore"]);

//Get the user store
const userStore = inject("userStore");
//Get units of the user
const userUnits = computed(() => {
  if (userStore.state.user.units) {
    return userStore.state.user.units.data;
  }
  return [];
});
const unitSelected = ref();

const showZone = () => {
  emit("showingZone");
};

const showTruckSpecific = () => {
  emit("showingTruckSpecific");
};

const displayCSAscore = () => {
  emit("displayCSAscore");
};

const buildRangeObject = (dateStr) => {
  return dateStr && dateStr?.split(":").length !== 0 ? { hours: dateStr.split(":")[0], minutes: dateStr.split(":")[1], seconds: 0 } : {}
}

if (props.payload) {
  filters.uuid = props.payload.uuid ?? null;
  filters.date = props.payload.date ? new Date(`${props.payload.date}T00:00:00`) : new Date();
  const now = new Date();
  filters.timeRange[0] = props.payload.start ? buildRangeObject(props.payload.start) : { hours: now.getHours() > 11 ? now.getHours() - 11 : 0, minutes: 0, seconds: 0 };
  filters.timeRange[1] = props.payload.end ? buildRangeObject(props.payload.end) : { hours: now.getHours(), minutes: now.getMinutes(), seconds: 0 };
}

if (filters.uuid) {
  const correspondingUnit = userUnits.value.filter(
    (u) => u.unit_uuid === filters.uuid
  );
  if (correspondingUnit.length !== 0) {
    unitSelected.value = correspondingUnit[0];
    filters.unit_name = correspondingUnit[0].unit_name;
  }
}

const unitnameSelector = ref(null);
//Reset filters
const resetFilters = () => {
  router.push({
    name: "MapRoutes",
  });
};

const filtersValid = computed(() => {
  if (filters.uuid && filters.date && filters.timeRange) {
    return true;
  } else {
    return false;
  }
});

watch(
  () => filters.unit_name,
  (val) => {
    if (val) {
      filters.uuid = userUnits.value.filter(
        (u) => u.unit_name === filters.unit_name
      ).length
        ? (filters.uuid = userUnits.value.filter(
          (u) => u.unit_name === filters.unit_name
        )[0].unit_uuid)
        : null;
    } else {
      filters.uuid = null;
    }
  },
  { deep: true }
);

const buildRangeString = (timeObject) => {
  const timeRange = `${goodTimeFormat(timeObject.hours)}:${goodTimeFormat(timeObject.minutes)}:00`;
  return timeRange;
}

const search = () => {
  const [startTime, endTime] = filters?.timeRange;

  // Convert each to total seconds
  function toSeconds(time) {
    const h = parseInt(time.hours, 10);
    const m = parseInt(time.minutes, 10);
    const s = time.seconds;
    return h * 3600 + m * 60 + s;
  }

  const startSeconds = toSeconds(startTime);
  const endSeconds   = toSeconds(endTime);

  // Compute the difference in seconds
  let diff = endSeconds - startSeconds;

  // Check if more than 12 hours
  if (diff > 155052) {
    notificationsStore.addNotification({
      type: "error",
      title: `You can't select more than 24 hours of data`,
      content: "Please try reducing the time range or contact support.",
    });
    return;
  }
  const date = formatDate(filters.date);
  router.push({
    name: "MapRoutes",
    params: {
      uuid: filters.uuid,
    },
    query: {
      date: date,
      start: buildRangeString(filters?.timeRange[0]),
      end: buildRangeString(filters?.timeRange[1])
    },
  });
};
</script>

<style lang="scss">
.reset {
  @apply flex cursor-pointer;

  &:hover {
    @apply text-blue;

    svg {
      g {
        fill: blue;
      }
    }
  }
}
</style>