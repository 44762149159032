<template>
  <div id="gmap-popin">
    <div id="gmap-popin__header">
      <div>
        <span>
          {{ formatDate(data?.local_time) }} <br/>
          <i id="gmap-popin__header__timezone">
            {{ t(`timezone`) }}: {{ last_tracking?.local_timezone}}
          </i>
        </span>
      </div>
      <div @click="close()" class="h-4 w-4">
        <XMarkIcon />
      </div>
    </div>
    <div class="bg-lightgray px-4 py-3">
      <span>{{ t(`mapPage.route.unitName`) }}:</span>
      <span class="ml-2 font-bold">{{ data?.unit_name }}</span>
    </div>
    <div class="text-xs p-4 space-y-3">
      <div class="flex items-center justify-between">
        <span>{{ t(`mapPage.route.vehicleSpeed`) }}:</span>
        <p class="text-blue font-bold" v-if="data.type === 'running'">
          <KmhOrMph :value="last_tracking.gnss_speed_kmh"  :valueUnit="deemUnit()"/>
        </p>
        <p class="text-blue font-bold" v-if="data.type === 'idle'">{{ t(`mapPage.route.idle`) }}</p>
        <p class="text-blue font-bold" v-if="data.type === 'stopped'">{{ t(`mapPage.route.stopped`) }}</p>
      </div>
      <div class="flex items-center justify-between">
        <p>{{ t(`mapPage.route.postedSpeedLimit`) }}:</p>
        <p v-if="!isDisconnected">
          <KmhOrMph :value="last_tracking.esmart_algo_kmh === 0 ? last_tracking.map_matched_kmh : last_tracking.esmart_algo_kmh" name="posted_speed_limit" :valueUnit="deemUnit()"/>
        </p>
        <p v-else>
          -
        </p>
      </div>
      <div class="flex items-center justify-between">
        <p>{{ t(`mapPage.route.allowedSpeedLimit`) }}:</p>
        <p v-if="!isDisconnected">
          <KmhOrMph :value="last_tracking.to_apply_kmh" name="allowed_speed" :valueUnit="deemUnit()"/>
        </p>
        <p v-else>
          -
        </p>
      </div>
      <div class="flex items-center justify-between">
        <p>{{ t(`mapPage.route.active`) }}:</p>
        <Active :value="isActive" />
      </div>
      <div class="flex items-center justify-between" v-if="isDisconnected">
        <p class="underline font-bold">{{ t(`mapPage.route.disconnected`) }}</p>
      </div>
      <div class="p-4 pt-2 border-t-1 border-[#ebf5ff] pb-0">
        <router-link :to="link" class="flex items-center text-blue font-bold cursor-pointer">
          <span> {{ t(`mapPage.route.vehicleRoute`) }} </span>
          <ArrowLongRightIcon class="ml-2 h-4" />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useTranslation } from "../../../composables/lang";
import { formatedDate, formatDateTime, formatTime } from "../../../utils/dates";
import { ArrowLongRightIcon } from "@heroicons/vue/24/solid";
import { XMarkIcon } from "@heroicons/vue/24/solid";

const { t } = useTranslation();

const emit = defineEmits(["close"]);

const props = defineProps({
  data: {
    type: Object,
    requiered: true,
  },
  isKmh: Boolean
});

const data = computed(() => props.data);
const last_tracking = computed(() => props.data?.data?.last_tracking);
const isDisconnected = computed(() => disconnected(props.data?.data?.last_event));
const isActive = computed(() => isActivated(props.data?.data));

const link = computed(() => {
  const now = new Date();
  const endDate = new Date(now.getTime() + 1 * 2 * 60 * 1000);
  // end = now - 10 hours
  let startDate = new Date(now.getTime() - 23 * 60 * 60 * 1000);
  const startOfToday = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 1);
  if (startDate < startOfToday) {
    startDate = startOfToday;
  }
  return {
    path: `/map/routes/${props.data?.data?.unit_uuid}`,
    query: {
      unit_name: props.unit_name,
      date: formatDateTime(new Date()).split("T")[0],
      start: formatTime(startDate),
      end: formatTime(endDate),
    },
  };
});

const disconnected = (date) => {
  const now = new Date();
  const lastEvent = new Date(date);
  const fourteenDaysAgo = new Date(now.getTime() - (14 * 24 * 60 * 60 * 1000));
  return lastEvent <= fourteenDaysAgo;
}

const deemUnit = () => {
  return data?.value?.data?.last_tracking?.country_code.includes('CAN') ? 'kmh' : props.isKmh ? 'kmh' :  'mph';
}

const isActivated = (data) => {
  const namedShadow = data.last_named_shadow?.customer_configuration?.feature_activation
  return namedShadow ? namedShadow?.speed_limiter_enabled : data?.last_shadow?.mcu_config?.speed_limiter?.enable;
}

const formatDate = (date) => {
  const localTime = date.split(".")[0];
  return formatedDate(new Date(localTime));
};

const close = () => {
  emit("close");
};
</script>

<style lang="scss" scoped>
#gmap-popin {
  width: 256px;
  position: relative;
  overflow: hidden;
  background-color: #fff;

  @apply flex flex-col rounded shadow-2xl;

  &__header {
    @apply bg-black md:bg-darkgray flex items-center justify-between px-4 py-3;

    p {
      @apply text-white font-bold text-xs;
    }

    div {
      font-size: 20px;

      @apply text-white font-bold cursor-pointer;
    }

    &__timezone {
      font-size: 11px;
    }
  }

  &__content {
    @apply flex-1 overflow-y-auto w-full overflow-x-hidden rounded bg-white;
  }
}
</style>